<template>
  <el-dialog title="编辑项目" v-model="visible">
    <el-form ref="editConfigForm" :rules="rules" :model="editConfigForm" label-width="140px"
      :disabled="dialogStatus === 'detail'">
      <el-row>
        <el-col :span="24">
          <el-form-item label="支付平台" prop="payType">
            <el-radio-group v-model="editConfigForm.payType">
              <el-radio label="1">微信</el-radio>
              <el-radio label="2">支付宝</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属学院/单位" prop="collegeId">
            <el-select v-model="editConfigForm.collegeId">
              <el-option v-for="item in collegeList" :key="item.id" :label="item.val" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商户私钥" prop="privateKey">
            <el-input v-model="editConfigForm.privateKey" placeholder="请输入私钥路径，仅支持classpath://路径"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="支付平台应用ID" prop="appId">
            <el-input v-model="editConfigForm.appId" placeholder="请输入支付平台应用ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商户pem证书" prop="certificate">
            <el-input v-model="editConfigForm.certificate" placeholder="请输入商户pem证书"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="支付平台商户ID" prop="mchId">
            <el-input v-model="editConfigForm.mchId" placeholder="请输入支付平台商户ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商户p12证书" prop="certificateP12">
            <el-input v-model="editConfigForm.certificateP12" placeholder="请输入商户p12证书"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :spen="24">
          <el-form-item label="商户名称" prop="mchName">
            <el-input v-model="editConfigForm.mchName" placeholder="请输入商户名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :spen="24">
          <el-form-item label="商户序列号" prop="mchSerialNo">
            <el-input v-model="editConfigForm.mchSerialNo" placeholder="请输入商户序列号"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :spen="24">
          <el-form-item label="api密钥V2版本" prop="apiKey">
            <el-input v-model="editConfigForm.apiKey" placeholder="请输入api密钥V2版本"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :spen="24">
          <el-form-item label="api密钥V3版本" prop="apiKey3">
            <el-input v-model="editConfigForm.apiKey3" placeholder="请输入api密钥V3版本"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :spen="24">
          <el-form-item label="回调地址" prop="callBack">
            <el-input v-model="editConfigForm.callBack" placeholder="请输入回调地址，用于应用平台业务逻辑处理，https协议"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button v-if="dialogStatus !== 'detail'" @click="editConfig" type="primary">保 存</el-button>
      <el-button @click="dialogClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { handleCatchStatus } from "@/api/handler";
  export default {
    data() {
      return {
        visible: false,
        editConfigForm: {
          payType: '1',
          collegeId: '',
          appId: "",
          mchId: "",
          mchName: "",
          mchSerialNo: "",
          privateKey: "",
          certificate: "",
          certificateP12: "",
          apiKey: "",
          apiKey3: "",
          callBack: "",
        },
        resetForm: {
          payType: '1',
          collegeId: '',
          appId: "",
          mchId: "",
          mchName: "",
          mchSerialNo: "",
          privateKey: "",
          certificate: "",
          certificateP12: "",
          apiKey: "",
          apiKey3: "",
          callBack: "",
        },
        rules: {
          appId: [
            { required: true, message: "请输入支付平台应用ID", trigger: "blur" },
          ],
          mchId: [
            { required: true, message: "请输入支付平台商户ID", trigger: "blur" },
          ],

          mchSerialNo: [
            { required: true, message: "请输入商户序列号", trigger: "blur" },
          ],
          privateKey: [
            { required: true, message: "请输入商户私钥", trigger: "blur" },
          ],
          certificate: [
            { required: true, message: "请输入商户pem证书", trigger: "blur" },
          ],
          certificateP12: [
            { required: true, message: "请输入商户p12证书", trigger: "blur" },
          ],
          apiKey: [
            { required: true, message: "请输入api密钥V2版本", trigger: "blur" },
          ],

          callBack: [
            { required: true, message: "请输入回调地址", trigger: "blur" },
          ],
        },
        collegeList: [],
      };
    },
    emits: ["callback", "dialogVisible"],
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      dialogForm: {
        type: Object,
      },
      dialogStatus: {
        type: String,
      },
      callback: {
        type: Function,
      },
    },
    watch: {
      dialogVisible(newval, oldval) {
        this.visible = newval;
      },
      dialogForm: {
        handler(newval, oldval) {
          let temp = JSON.parse(JSON.stringify(newval));
          let reset = JSON.parse(JSON.stringify(this.resetForm))
          this.editConfigForm = Object.assign(reset, temp);
          if (this.editConfigForm.collegeId) {
            this.editConfigForm.collegeId = Number(this.editConfigForm.collegeId);
          }

        },
        deep: true,
      },
    },
    methods: {
      dialogClose() {
        this.$refs.editConfigForm.resetFields();
        this.$emit("callback", "");
      },
      // 获取学校列表
      getCollegeList() {
        this.$api.getCollegeMap().then((data) => {
          console.log(data, "get college");
          this.collegeList = data;
        });
      },
      editConfig() {
        this.$refs.editConfigForm.validate((valid) => {
          if (valid) {
            if (this.dialogStatus === "create") {
              this.createConfig();
            } else {
              this.updateConfig();
            }
          }
        });
      },
      createConfig() {
        this.$api
          .createPaymentConfig(this.editConfigForm)
          .then((res) => {
            this.$message.success("新增成功");

            this.dialogClose();
          })
          .catch(handleCatchStatus);
      },
      updateConfig() {
        this.$api
          .updatePaymentConfig(this.editConfigForm)
          .then((res) => {
            this.$message.success("编辑成功");
            this.dialogClose();
          })
          .catch(handleCatchStatus);
      },
    },
    created() {
      console.log(this.editConfigForm, "created");
      this.getCollegeList();
    },
    mounted() { },
  };
</script>
<style scoped lang="scss">
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
</style>