<template>
  <div class="batch-qrcode-container">
    <el-form :model="requestParam" label-width="80px">
      <el-row>
        <el-col :span="8">
          <el-form-item prop="collegeId" label="所属学校">
            <el-select clearable v-model="requestParam.collegeId" placeholder="请选择学校">
              <el-option v-for="item in collegeList" :key="item.id" :label="item.val" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="mchId" label="商户ID">
            <el-input clearable v-model="requestParam.mchId" placeholder="请输入商户ID"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="flex-end">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="editConfig('create', {})" type="primary">新增</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table :data="configList.list" ref="batchCodeTable" border stripe class="batch-code">
      <el-table-column label="所属学校" prop="collegeName" />
      <el-table-column label="平台类型" prop="payType" />
      <el-table-column label="商户ID" prop="mchId" />
      <el-table-column label="应用ID" prop="appId" />
      <el-table-column label="商户名称" prop="mchName" />
      <el-table-column label="创建日期" prop="created" />
      <el-table-column label="最后修改日期" prop="updated" />
      <el-table-column label="最后修改人" prop="updatedName" />
      <el-table-column label="禁用" prop="createUserName">
        <template #default="scope">
          <el-switch @change="
              (data) => {
                toggleConfigStatus(data, scope.row);
              }
            " v-model="scope.row.isDelete" active-value="1" inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否分流" prop="createUserName">
        <template #default="scope">
          <span v-if="scope.row.randSort === '1'">全局随机</span>
          <span v-if="scope.row.randSort === '2'">{{ scope.row.weight }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button @click="editConfig('detail', scope.row)" type="text">查看</el-button>
          <el-button @click="editConfig('update', scope.row)" type="text">修改</el-button>
          <el-button @click="editRate(scope.row)" type="text">分流</el-button>
          <!-- <el-button type="text">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next,jumper" :total="configList.total" @current-change="applyCurrentPage" />
    <EditConfigDialog v-model="editConfigDialogVisible" :dialogStatus="editConfigDialogStatus"
      :dialogForm="editConfigDialogForm" @callback="editConfigCb"></EditConfigDialog>
    <el-dialog title="设置分流" v-model="editRateDialogVisible">
      <el-form :model="editRateDialogForm" ref="rateForm" :rules="rules">
        <el-form-item label="选择算法" prop="randSort">
          <el-select v-model="editRateDialogForm.randSort">
            <el-option value="1" label="全局随机"></el-option>
            <el-option value="2" label="权重随机"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editRateDialogForm.randSort === '2'" label="权重值" prop="weight">
          <el-input-number v-model="editRateDialogForm.weight" :min="1" :max="100"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editRateConfirm" type="primary">保 存</el-button>
        <el-button @click="editRateDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import EditConfigDialog from "./components/EditConfigDialog";
  import { handleCatchStatus } from "../../api/handler";
  export default {
    data() {
      return {
        requestParam: {
          collegeId: "",
          mchId: "",
          page: 1,
          pageSize: 10,
        },
        configList: {
          list: [],
          page: 1,
          total_count: 0,
        },
        // 新增条码弹框是否可见
        dialogVisible: false,
        // 新增条码弹框数据对象
        createCodeForm: {
          no: "",
          seq: "",
          num: 0,
        },
        selectDatetime: [],
        formRules: {},
        // 选中行
        selections: [],
        // 查看条码弹框是否可见
        checkCodeDialogVisible: false,
        // 条码明细
        checkCodeDetail: [],
        // 编辑支付配置弹框是否可见
        editConfigDialogVisible: false,
        // 编辑支付配置弹框状态
        editConfigDialogStatus: "create",
        // 编辑支付配置弹框数据
        editConfigDialogForm: {},
        // 设置分流弹框是否可见
        editRateDialogVisible: false,
        // 设置分流弹框数据
        editRateDialogForm: {
          merchantId: "",
          randSort: "",
          weight: "",
        },
        rules: {
          randSort: [
            { required: true, message: "请选择随机算法", trigger: "blur" },
          ],
          weight: [{ required: true, message: "请输入权重值", trigger: "blur" }],
        },
        collegeList: [],
      };
    },
    components: {
      EditConfigDialog,
    },
    methods: {
      editRate(row) {
        this.editRateDialogForm.merchantId = row.merchantId;
        this.editRateDialogForm.randSort = "";
        this.editRateDialogForm.weight = 1;
        this.editRateDialogVisible = true;
      },
      // 获取学校列表
      getCollegeList() {
        this.$api.getCollegeMap().then((data) => {
          this.collegeList = data;
        });
      },
      editRateConfirm() {
        this.$refs.rateForm.validate((valid) => {
          if (valid) {
            let query = "?";
            let list = [];
            let keys = Object.keys(this.editRateDialogForm);
            for (let key of keys) {
              list.push(key + "=" + this.editRateDialogForm[key]);
            }
            query += list.join("&");
            this.$api
              .ratePaymentConfig(query)
              .then((res) => {
                this.editRateDialogVisible = false;
                this.$message.success("设置成功");
                this.getConfigList();
              })
              .catch(handleCatchStatus);
          }
        });
      },
      // 切换是否禁用状态
      toggleConfigStatus(status, row) {
        console.log(status, "111");
        if (!row.merchantId) return;
        if (status === "0") {
          this.$api
            .onPaymentConfig("?merchantId=" + row.merchantId)
            .then((res) => {
              this.$message.success(res.content);
              this.getConfigList();
            })
            .catch(handleCatchStatus);
        } else {
          this.$api
            .offPaymentConfig("?merchantId=" + row.merchantId)
            .then((res) => {
              this.$message.success(res.content);
              this.getConfigList();
            })
            .catch(handleCatchStatus);
        }
      },
      editConfigCb() {
        console.log("callback");
        this.editConfigDialogVisible = false;
        this.getConfigList();
      },

      // 翻页
      applyCurrentPage(page) {
        this.requestParam.page = page;
        this.getConfigList();
      },
      selectTime(data) {
        this.requestParam.startDate = data[0];
        this.requestParam.endDate = data[1];
      },
      search() {
        this.requestParam.page = 1;
        this.getConfigList();
      },
      reset() { },
      // 获取批量条码记录
      getConfigList() {
        this.$api.getPaymentConfigList(this.requestParam).then((data) => {
          this.configList = data;
        });
      },
      editConfig(type, data) {
        this.editConfigDialogStatus = type;
        this.editConfigDialogForm = data;
        this.editConfigDialogVisible = true;
      },
    },
    created() {
      this.getCollegeList()
      this.getConfigList();
    },
    mounted() { },
  };
</script>
<style scoped lang="scss">
  .batch-qrcode-container {
    .flex-end {
      display: flex;
      justify-content: flex-end;
      height: 40px;
    }

    .el-select,
    .el-input {
      width: 100%
    }

    .el-pagination {
      text-align: center;
      margin-top: 20px;
    }

    .dialog-footer {
      text-align: right;
    }

    .batch-code {
      margin-top: 20px;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>